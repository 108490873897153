import { useEffect } from "react";
import { useRouter } from "next/router";

import { Box, CloseButton, Flex, Text } from "@chakra-ui/react";

import NavLink from "@components/NavLink";
import { VscBook } from "react-icons/vsc";
// import { BsQuestionCircle } from 'react-icons/bs';
// import { IoDocumentOutline } from 'react-icons/io5';

const LinkItems = [
  { label: "Reglas", icon: VscBook, href: "/regulations" },
  // { label: "¿Dudas?", icon: BsQuestionCircle, href: "/faq" },
  // { label: "Política de privacidad", icon: IoDocumentOutline, href: "/terms" },
];

export default function Sidebar({ onClose, ...rest }: { onClose: any, rest?: any }) {
  const router = useRouter();

  useEffect(() => {
    router.events.on("routeChangeComplete", onClose);
    return () => {
      router.events.off("routeChangeComplete", onClose);
    };
  }, [router.events, onClose]);

  return (
    <Box
      transition="3s ease"
      borderRight="1px"
      w={{ base: "full" }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text color="black" fontSize="2xl">
          {process.env.NEXT_PUBLIC_APP_NAME}
        </Text>
        <CloseButton color="black" display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link, i) => (
        <NavLink key={i} link={link} />
      ))}
    </Box>
  );
}
