import {
  Container,
  Box,
  VStack,
  Text,
  Link,
  // HStack,
  // StackDivider,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

export default function Footer() {
  return (
    <Box
      as={motion.div}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition="1s linear"
      viewport={{ once: true }}
    >
      <Container maxW={{ base: 'full', md: '8xl' }} py={5}>
        <VStack spacing={5}>
          {/* <HStack spacing={2} divider={<StackDivider borderColor="gray.600" />}>
            <Link
              fontSize={['xs', 'sm']}
              fontWeight={600}
              color="white"
              _hover={{ textDecor: 'underline' }}
              href="/terms"
            >
              Políticas de Privacidad
            </Link>

            <Link
              fontSize={['xs', 'sm']}
              fontWeight={600}
              color="white"
              _hover={{ textDecor: 'underline' }}
              href="/regulations"
            >
              Regulación
            </Link>

            <Link
              fontSize={['xs', 'sm']}
              fontWeight={600}
              color="white"
              _hover={{ textDecor: 'underline' }}
              href="/faq"
            >
              ¿Dudas?
            </Link>
          </HStack> */}
          <Link
            href="/regulations"
            _hover={{ textDecor: 'underline' }}
           fontWeight={600}
              color="white"
              >BASES Y CONDICIONES</Link>
          <Text color="white" fontSize={['xs', 'sm']} textAlign="center">
          Promoción válida del 01/09/2024 al 31/12/2024. Bases y condiciones en: <a href='https://desafiofutbol.zed.com/regulations'>https://desafiofutbol.zed.com</a>
          </Text>
        </VStack>
      </Container>
    </Box>
  );
}
