import {
  Flex,
  Text,
  Button,
  Box,
  HStack,
  StackDivider,
  // Link,
  // Icon,
  VStack,
  useToast,
} from '@chakra-ui/react';
import {  MdChevronRight, MdLockOutline, 
  // MdMenu 
} from 'react-icons/md';
import UserProfile from '@components/UserProfile';
import router from 'next/router';
import { motion } from 'framer-motion';
import { useSession } from 'next-auth/react';
import { useStages } from '@hooks/useStages';
import { GameStatus } from '@interfaces/Game';


export default function Header({
  onOpen,
  ...rest
}: {
  onOpen: any;
  rest?: any;
}) {
  const { data: _session, status } = useSession();
  const toast = useToast();
  const {getQuizStage} = useStages()
  
  function renderActionButton() {
    const currentStage = getQuizStage();
    if (!currentStage || !currentStage?.games[0]) {
      return <></>;
    }
    // game.
    let game = currentStage?.games[0];
    switch (Number(currentStage?.games[0].status)) {
      case GameStatus.Unlocked:
        return (
          <>
            <Button
              rightIcon={<MdChevronRight color='#14284B' fontSize={"18px"} />}
              bgColor="#FFBB38"
              fontFamily={"Inter"}
              fontWeight={500}
              textAlign="center"
              justifyContent="center"
              size={['sm', 'sm']}
              onClick={() => {
                router.push(
                  `/game?id_game=${game.idGame}&id_stage=${currentStage.id}&type=${game.type}`
                );
              }}
            >
              JUGAR AHORA
            </Button>
          </>
        );
      case GameStatus.Played:
        return (
          <>
            <Button
              rightIcon={<MdLockOutline />}
              bgColor="#F14971"
              color="white"
              fontWeight={400}
              size={['sm', 'sm']}
              spinnerPlacement="start"
              onClick={() =>
                toast({
                  title: '¡Espera hasta mañana!',
                  description:
                    'Vuelve mañana a jugar de nuevo. O visita tu perfil para más información.',
                  status: 'warning',
                  duration: 9000,
                  isClosable: true,
                })
              }
            >
            ¿HAS JUGADO HOY?
            </Button>
          </>
        );
    }
  }
  return (
    <Flex
      position="sticky"
      top="0"
      px={[5,10,15,20]}
      height={status === 'unauthenticated'?'80px':'90px'}
      zIndex="1"
      alignItems="center"
      backgroundColor="#1F1960"
      opacity={0.95}
      backdropFilter="saturate(180%) blur(5px)"
      justifyContent="space-between"
      {...rest}
    >
      {/* <Icon
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        color="white"
        w={6}
        h={6}
        size="lg"
        aria-label="open menu"
        as={MdMenu}
        zIndex={1}
      /> */}

      <HStack zIndex={1} spacing={2} divider={<StackDivider borderColor="gray.600" />}>
      {status === 'unauthenticated' && (
        <Box
          display={{ base: 'none', md: 'flex' }}
          as={motion.div}
          dragConstraints={{ left: -100, right: 100 }}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          transition="0.05s linear"
          pr={5}
        >
          <Button
            borderRadius={10}
            colorScheme="orange"
            onClick={() => {
              router.push('/login');
            }}
            size="lg"
          >
            <Text color="white">Quiero participar</Text>
          </Button>
        </Box>
      )}
        {/* <Link
          fontWeight={600}
          color="white"
          _hover={{ textDecor: 'underline' }}
          display={{ base: 'none', md: 'flex' }}
          href="/regulations"
        >
          Reglas
        </Link> */}

        {/* <Link
          fontWeight={600}
          color="white"
          _hover={{ textDecor: 'underline' }}
          display={{ base: 'none', md: 'flex' }}
          href="/faq"
        >
          ¿Dudas?
        </Link> */}
      </HStack>
      {status !== 'unauthenticated' && (
        <VStack
          position="absolute"
          marginLeft="auto"
          margin-right="auto"
          left={0}
          right={0}
          zIndex={0}
          spacing={1}
        >
          {renderActionButton()}
        </VStack>
      )}
      <UserProfile />
    </Flex>
  );
}
