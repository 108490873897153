import { GameDetailType } from '@interfaces/Game';
import { Stage } from '@interfaces/Stage';
import logger from '@logger/logger';
import { getStagesReq } from '@services/stages';
import React, { createContext, useState } from 'react';

interface StagesContextInterface {
  stages: Stage[] | undefined;
  setStages({}: Stage[]): any;
  getQuizStage(): Stage | undefined;
  loadNewStages(): Promise<any>;
  loading: boolean;
}

interface StageInterfaceProps {
  children: React.Component | any;
  stages?: Stage[];
}

export const StagesContext = createContext<StagesContextInterface>(
  {} as StagesContextInterface
);

export default function StagesProvider({
  children,
  stages: initialStages,
}: StageInterfaceProps) {
  const [stages, setStages] = useState<Stage[] | undefined>(initialStages);
  const [loading, setIsLoading] = useState(false);

  const loadNewStages = async () => {
    logger.info('loadStates called');
    setIsLoading(true);
    try {
      const data = await getStagesReq();
      setStages(data as Stage[]);
      setIsLoading(false)
      return data as Stage[];
    } catch (e) {
      setIsLoading(false)
      return undefined;
    }
    // getStagesReq().then((data) => {
    //   console.log(data);
    // });
  };
  // console.log("stages",stages)

  const getQuizStage = () => {
    let stage;
    stage = stages?.find(
      (v) => v.games[0]?.type == GameDetailType.Quiz && v.games[0].idGame == 47
    );
    if (stage == null || stage == undefined) {
      return {
        id: 18,
        name: 'Desafio Futbol 2',
        start_date: '2023-03-01T00:00:00.000Z',
        end_date: '2099-12-31T16:14:31.000Z',
        next_draw: '',
        card_header_style: {
          icon_url: 'https://promozed-benja.vercel.app/icons/flame.svg',
          icon: 'flame',
          bg_color: '#8BC96C',
          font_color: '',
        },
        games: [
          {
            idPromoFase: 18,
            idStage: 18,
            idGame: 47,
            type: 6,
            name: 'Quiz',
            description: 'Quiz Desafio futbol 2',
            card_style: {
              bg_color:
                'linear-gradient(0deg, rgba(0, 0, 0, 0.54), rgba(0, 0, 0, 0.54)), linear-gradient(153.03deg, #009479 4.22%, #04866e 94.92%)',
              font_color: '#ffffff',
              action_button_bg_color:
                'linear-gradient(180deg, #f14971 2.7%, #d63537 100%)',
              action_button_font_color: '#f7f8f9',
              icon:"",
              icon_url:""
            },
            status: 0,
            user_score: [
              { type: 'coupons', value: 0 },
              { type: 'points', value: 0 },
            ],
          },
        ],
      } as Stage;
    }
    return stage;
  };
  return (
    <StagesContext.Provider
      value={{
        stages,
        setStages,
        loadNewStages,
        getQuizStage,
        loading
      }}
    >
      {children}
    </StagesContext.Provider>
  );
}
